.div_all_apartment {
    width: 80%;
    position: relative;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    .div_all_apartment {
        width: 90%;
    }
}