.apartmentShow_forHomePage .header {
  color: rgb(255, 94, 0);
  font-weight: 700;
  width: 100%;
  /* position: absolute; */
  padding: 10px;
  /* height: 90px; */
}

.apartmentShow_forHomePage .header1 {
  font-weight: 700;
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center
}

.apartmentShow_forHomePage .nameHeader {
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.apartmentShow_forHomePage .addressHeader {
  font-size: 2.1rem;
  margin-right: 1vw;
  display: flex;
  align-items: center;
}

.apartmentShow_forHomePage .phoneHeader {
  font-size: 3rem;
  margin-left: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.apartmentShow_forHomePage .bgcolorHeader {
  background-color: var(--blue);
  color: white !important;
  padding: 20px 0;
  box-shadow: 0 0 10px rgb(173, 173, 173);

  border-bottom: rgb(214, 174, 255) 0.4vw solid;
}

.apartmentShow_forHomePage .noneShowFavorite {
  display: none;
}

.apartmentShow_forHomePage .yesShowFavorite {
  width: 15%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .apartmentShow_forHomePage .header1 {
    width: 75%;
  }
}

/* @media only screen and (max-width: 800px) {
  .header1 {
    width: 85%;
  }
} */
@media only screen and (max-width: 600px) {
  .apartmentShow_forHomePage .header {
    width: 100%;
    padding: 0px;
    /* margin-top: 20px; */
  }

  .apartmentShow_forHomePage .bgcolorHeader .header1 {
    width: 75%;
  }

  .apartmentShow_forHomePage .bgcolorHeader {
    padding: 15px 0%;
  }

  .apartmentShow_forHomePage .header1 {
    width: 90%;
    margin: 0px auto;
  }

  .apartmentShow_forHomePage .header_mobile {
    display: flex;
  }

  .apartmentShow_forHomePage .nameHeader {
    font-size: 2rem;
    display: block;

    /* display: flex;
    align-items: center; */
  }

  .apartmentShow_forHomePage .addressHeader {
    font-size: 1.5rem;
    margin-right: 0;
    display: block;
  }

  .apartmentShow_forHomePage .phoneHeader {
    font-size: 1.7rem;
  }

  .apartmentShow_forHomePage .phone_icon {
    background-color: green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }
}