.div_favorite_in_header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6rem;
}

.div_favorite_in_header .div_label_favorite {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    padding: 0 2rem;
    font-size: 1.6rem;
    z-index: 1000;
}

.div_favorite_in_header .div_label_favorite:hover {
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
}

.div_favorite_in_header .div_icon_favorite {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 100%;
}

.div_favorite_in_header .icon_favorite {
    font-size: 4rem;
    color: red;
}


.div_favorite_in_header .amount_favorite {
    color: white;
    font-size: 1.4rem;
    font-weight: 400;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.div_favorite_in_header .div_list_favorite_in_header {
    width: 200%;
    margin: 0 -50%;
    max-height: 35rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(207, 207, 207);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.div_favorite_in_header .msg_no_favorite {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--blue);
    line-height: 200%;
}

.div_favorite_in_header .box_apartment_favorite {
    width: calc(100% - 4rem);
    height: 10rem;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgb(207, 207, 207);
    margin: 1rem auto;
    position: relative;
}

.div_favorite_in_header .div_add_favorite {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    width: 2rem;
    height: 2rem;
    padding: 0.3rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 5px rgb(207, 207, 207);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.div_favorite_in_header .div_add_favorite:hover {
    transform: scale(1.3);
}

.div_favorite_in_header .img_apartment_favorite {
    width: 50%;
    height: 100%;
    border-radius: 5px;
    border-left: 1px solid rgb(207, 207, 207);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* object-fit: contain; */
}

.div_favorite_in_header .info_apartment_favorite {
    width: calc(50% - 4rem);
    height: calc(100% - 2rem);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}


.div_favorite_in_header .name_apartment_favorite {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--blue);
}

.div_favorite_in_header .city_apartment_favorite {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--blue);
}

.div_favorite_in_header .phone_apartment_favorite {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--blue);
}

@media only screen and (max-width: 600px) {
    .div_favorite_in_header {
        height: 100%;
    }

    .div_favorite_in_header .div_label_favorite:hover {
        background-color: transparent;
    }


    .div_favorite_in_header .div_label_favorite {
        flex-direction: column;
        padding: 0 2rem;
        font-size: 1.2rem;
        gap: 0;
        justify-content: center;
        width: 3rem;
    }

    .div_favorite_in_header .div_icon_favorite {
        height: fit-content;
    }

    .icon_favorite {
        font-size: 3rem !important;
    }

    .amount_favorite {
        font-size: 1.1rem !important;
        font-weight: 400;
    }


}