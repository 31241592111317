.screen_apartment_show {
    width: calc(100% - 7rem);
    height: calc(100% - 7rem);
    border-radius: 20px;
    background-color: #f8f8f8bf;
    /* animation: animation_background 5s infinite; */
    border: 0.5rem solid var(--blue);
    padding: 3rem;
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--blue);
    overflow: auto;
}

@keyframes animation_background {
    0% {
        background-color: #f1febda8;
    }

    50% {
        background-color: #febdfda8;
    }

    100% {
        background-color: #f1febda8;
    }
}

.text_screen_apartment_show {
    height: 100%;
    width: 60%;
    margin: 0 40% 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.img_screen_apartment_show {
    position: absolute;
    width: 40%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_screen_apartment_show img {
    width: 70%;
    height: 70%;
    object-fit: contain;
    transform: rotate(8deg);
}

.icon_special {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.4rem;
}

.icon_screen_apartmentShow {
    margin: 0 0 0 2rem;
}


@media only screen and (max-width: 600px) {
    .screen_apartment_show {
        width: calc(100% - 4.4rem);
        height: calc(100% - 4.4rem);
        border-radius: 10px;
        border: 0.2rem solid var(--blue);
        padding: 2rem;
        font-size: 1.2rem;
    }

    .text_screen_apartment_show {
        width: 100%;
        margin: 0;
    }

    .icon_special {
        font-size: 1.2rem;
    }

    .img_screen_apartment_show {
        position: absolute;
        width: 100%;
        height: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    .img_screen_apartment_show img {
        width: 80%;
        height: 80%;
    }

}

@media only screen and (max-width: 400px) {
    .screen_apartment_show {
        font-size: 1.1rem;
    }

    .icon_special {
        font-size: 1.1rem;
    }

}