.carousel {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
}

.window_carousel {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.content_carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.animation_forward {
    animation: animation_forward 2s forwards;
    z-index: 1 !important;
}

@keyframes animation_forward {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }

}

.animation_backwards {
    animation: animation_backwards 2s forwards;
    top: -100% !important;
    z-index: 1 !important;
}

@keyframes animation_backwards {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

.opacity_background {
    animation: opacity_background 2s forwards;
}

@keyframes opacity_background {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.div_buttons_carousel {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    align-content: center;
}

.button_carousel {
    cursor: pointer;
}

.buttons_carousel_up {
    transform: rotate(180deg);
}

@media only screen and (max-width: 600px) {
    .div_buttons_carousel {
        width: 100%;
        height: 10%;
        bottom: 0;
        top: auto;
        right: auto;
        left: 0;
        flex-direction: row;
    }

    .window_carousel {
        width: 100%;
        height: 90%;
    }

}