.tabs_component {
  position: relative;
  margin: 4vw auto;
}


.tabs_component [id^="simple-tab-"] {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  color: var(--blue) !important;
  background-color: rgb(255, 255, 255) !important;
  border-top-left-radius: 1vw !important;
  border-top-right-radius: 1vw !important;
  border: solid var(--blue-lite) !important;
  border-width: 0.1vw 0.1vw 0vw 0.1vw !important;
  width: 17% !important;
}

.tabs_component .Mui-selected {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  color: rgb(255, 255, 255) !important;
  background-color: var(--blue) !important;
  border-top-left-radius: 1vw !important;
  border-top-right-radius: 1vw !important;
}

.tabs_component .MuiTabs-flexContainer {
  border-bottom: 0.2vw solid var(--blue) !important;
}

.tabs_component .MuiTabs-indicator {
  height: 0 !important;
}

.tabs_component .MuiBox-root {
  padding: 0 0 1vw !important;
}

@media only screen and (max-width: 600px) {
  .tabs_component [id^="simple-tab-"] {
    font-size: 1.6rem !important;
    border-top-left-radius: 4vw !important;
    border-top-right-radius: 4vw !important;
    width: 25% !important;
    min-width: 10px !important;
    padding: 8% 10% !important;
    display: flex !important;
    align-items: center;
    align-content: center;
    height: 5rem;
  }

  .tabs_component .Mui-selected {
    font-size: 1.6rem !important;
    border-top-left-radius: 4vw !important;
    border-top-right-radius: 4vw !important;
  }

}