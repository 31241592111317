.box_Replacement_Search {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: 2rem auto;
    cursor: pointer;
}

.box_Replacement_Search_google_ads {
    width: 90%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: 2rem auto;
    height: 220px;
    cursor: pointer;
}

.model_Replacement_Search {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
    font-size: 2rem;
    font-weight: 700;
    color: var(--white);
    text-align: center;
    padding: 0.5rem 0;
}

.div_AddToFavorite_replacementSearch {
    position: absolute;
    top: 2rem;
    left: 5%;
    height: 3.5em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
}

.apartment_Search_boxheader {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    color: var(--blue);
}


.div_all_city_name {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.city_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--blue);
    margin: 0 0 0.6rem 1.2rem;
}

.box_Replacement_Search_err {
    width: 70%;
    background-color: rgb(255, 255, 255);
    margin: 2rem auto;
    padding: 3rem 10%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--blue);

}

.publish_button {
    position: fixed;
    bottom: 2rem;
    left: 4rem;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .publish_button {
        left: 2rem;
        bottom: 70px;
    }


}