/* .div_description {
  grid-template-columns: 35% 65%;
} */

.special {
  padding: 2rem;
  column-count: 4;
}



@media only screen and (max-width: 900px) {
  .addApartment .special {
    column-count: 3;
  }
}

@media only screen and (max-width: 750px) {
  .div_description {
    grid-template-columns: 100%;
  }

  .addApartment .special {
    column-count: 2;
  }

}

@media only screen and (max-width: 600px) {
  .addApartment .special {
    column-count: 1;
  }

  .addApartment #short {
    width: 98%;
    height: 10rem !important;
  }

  .addApartment #short .textarea {
    height: 10rem !important;
  }

  .addApartment #long {
    width: 98%;
  }
}