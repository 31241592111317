.apartmentShowComponent {
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.information .info {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 55%;
  font-size: 2rem;
}

.information .special {
  column-count: 4;
  font-weight: 700;
  padding: 30px 0;
  font-size: 2rem;
}

.information li {
  list-style: none;
  line-height: 3.2rem;
}

.apartmentShowComponent .move_website_apartment {
  width: 100%;
}

.information .div_rentTime {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
  font-size: 1.8rem;
  color: var(--blue);
  font-weight: 700;
}

.apartmentShowComponent .div_images_slider {
  height: 40vw;
  width: 100%;
}

@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 1000px) {
  .apartmentShowComponent .div_images_slider {
    height: 55vw;
  }
}


@media only screen and (max-width: 1100px) {
  .information .special {
    column-count: 3;
    font-weight: 700;
    padding: 30px 0;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .information .info {
    display: block;
    font-size: 1.5rem;
  }

  .information .special {
    column-count: 2;
    font-weight: 700;
    padding: 15px 0;
    font-size: 1.5rem;
  }

  .information li {
    list-style: none;
    line-height: 3.2rem;
  }

  .information .div_rentTime {
    flex-direction: column;
    font-size: 1.6rem;

  }

}