.screen_replecment_search {
    width: calc(100% - 7rem);
    height: calc(100% - 7rem);
    border-radius: 20px;
    background-color: #f8f8f8bf;
    /* animation: animation_background 5s infinite; */
    border: 0.5rem solid var(--blue);
    padding: 3rem;
    position: relative;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--blue);
    overflow: auto;

}

@keyframes animation_background {
    0% {
        background-color: #f1febda8;
    }

    50% {
        background-color: #febdfda8;
    }

    100% {
        background-color: #f1febda8;
    }
}

.text_screen_replecment_search {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.img_screen_replecment_search {
    position: absolute;
    width: 50%;
    height: 70%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_screen_replecment_search img {
    width: 70%;
    height: 70%;
    object-fit: contain;
    transform: rotate(-8deg);
}

@media only screen and (max-width: 600px) {
    .screen_replecment_search {
        width: calc(100% - 4.4rem);
        height: calc(100% - 4.4rem);
        border-radius: 10px;
        border: 0.2rem solid var(--blue);
        padding: 2rem;
        font-size: 1.2rem;
    }

    .text_screen_replecment_search {
        height: 70%;
    }

    .img_screen_replecment_search {
        position: absolute;
        width: 100%;
        height: 30%;
        top: auto;
        bottom: 0;
        left: 0;
    }


}

@media only screen and (max-width: 400px) {
    .screen_replecment_search {
        font-size: 1.1rem;
    }

    .text_screen_replecment_search {
        height: 100%;
    }

    .img_screen_replecment_search {
        position: absolute;
        width: 100%;
        height: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        z-index: -1;
    }


}