.add_to_favorite {
  position: relative;
}

.favoriteIcon {
  color: red !important;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.favoriteIcon:hover {
  transform: scale(1.3);
  color: red !important;
}

.msg_err_add_to_favorite {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10rem;
  background-color: rgb(255, 255, 255);
  text-align: center;
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 400;
  z-index: 100;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid var(--blue-lite);
}

.cancel_msg_err {
  text-align: right;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .msg_err_add_to_favorite {
    width: 10rem;
    left: 0;
    transform: translate(0, 0);
  }

}