.container_arrow {
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 10rem;
    width: 2.5rem;
    border-radius: 3rem;
    background-color: rgba(255, 255, 255, 0.715);
}

.arrow_animate {
    position: absolute;
    width: calc(1rem * 2.5);
    height: calc(1rem * 0.8);
    opacity: 0;
    transform: scale(0.3);
    animation: move-arrow 6s ease-out infinite;
    line-height: 50px;
}

.arrow_animate:first-child {
    animation: move-arrow 6s ease-out 2s infinite;
}

.arrow_animate:nth-child(2) {
    animation: move-arrow 6s ease-out 4s infinite;
}

.arrow_animate:before,
.arrow_animate:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: var(--blue);
}

.arrow_animate:before {
    left: 0;
    transform: skewY(30deg);
}

.arrow_animate:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg);
}

@keyframes move-arrow {
    25% {
        opacity: 1;
    }

    33.3% {
        opacity: 1;
        transform: translateY(calc(1rem * 5.7));
    }

    66.6% {
        opacity: 1;
        transform: translateY(calc(1rem * 7.8));
    }

    100% {
        opacity: 0;
        transform: translateY(calc(1rem * 12) scale(0.5));
    }
}

@media only screen and (max-width: 600px) {
    .container_arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 3rem;
        background-color: rgba(255, 255, 255, 0.715);
    }

    .arrow_small_screen {
        color: var(--blue);
        transform: rotate(90deg);
    }

}