.ConnectWithUser {
  width: 100%;
}

.ConnectWithUser .diviconhiden {
  position: relative;
}

.ConnectWithUser .divErr {
  height: 20px;
  color: red;
  line-height: 1vw;
  font-size: 1.4rem;
  font-weight: 400;
}

.ConnectWithUser .iconhiden {
  position: absolute;
  bottom: 15px;
  left: 10px;
  cursor: pointer;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
} */