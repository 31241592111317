.SearchApartmentShow {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 10px;
  width: 75rem;
}

.SearchApartmentShow .info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "div_baner_r div_baner_l"
    "info_content_r info_content_l";
  column-gap: 20px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
}

.SearchApartmentShow .info_rent_only {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "div_baner_r div_baner_r"
    "info_content_r info_content_l";
  column-gap: 20px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
}

.SearchApartmentShow .div_baner_r {
  grid-area: div_baner_r;
}

.SearchApartmentShow .div_baner_l {
  grid-area: div_baner_l;
}

.SearchApartmentShow .info_content_r {
  grid-area: info_content_r;
  margin-top: -10px;
}

.SearchApartmentShow .info_content_l {
  grid-area: info_content_l;
  margin-top: -10px;
}


.SearchApartmentShow .div_all_city {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0px;
}

.SearchApartmentShow .div_details {
  width: 85%;
  margin: 10px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SearchApartmentShow .div_details_1 {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.div_details_2 {
  margin-inline-end: 10px;
  color: var(--blue-lite);
}


.SearchApartmentShow .div_description {
  border: 1px solid var(--blue-lite);
  border-radius: 10px;
  padding: 3px 10px;
  margin: 10px auto;
  width: 85%;
  max-width: 85%;
  min-height: 90px;
  font-size: 1.4rem;
  text-align: start;
  font-weight: 500;
  line-height: 2.2rem;
}

.SearchApartmentShow .div_date {
  width: 100%;
  color: var(--blue-lite);
  text-align: start;

}

.SearchApartmentShow .div_all_date {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.SearchApartmentShow .list_date_Tile {
  margin: 5px;
  width: 35px;
  border: 0.5px solid var(--blue-lite);
  position: relative;
  border-radius: 3px;
  padding: 0 2px;
  text-align: center;
  color: var(--blue);
  background-color: var(--purple-lite);
}

.SearchApartmentShow .list_date_heb {
  font-size: 1.4rem;
  font-weight: 600;
}

.SearchApartmentShow .list_month_heb {
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: -5px;
}

.SearchApartmentShow .list_date_cal {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 -2px 0 0;
  background-color: var(--blue);
  width: calc(100% + 4px);
  color: var(--white);
  padding: 3px 0px;
}

.div_send_message {
  width: calc(90% - 40px);
  margin: 20px auto;
  color: var(--blue-lite);
  text-align: start;
  border: 0.5px solid var(--blue-lite);
  border-radius: 10px;
  padding: 20px;
}

@media only screen and (max-width: 650px) {
  .SearchApartmentShow {
    display: block;
    font-size: 1.5rem;
    width: 60vw;
    margin: 0 auto;
  }

  .SearchApartmentShow .info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "div_baner_r"
      "info_content_r "
      "div_baner_l"
      "info_content_l ";
    column-gap: 0px;
  }

  .SearchApartmentShow .info_rent_only {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "div_baner_r"
      "info_content_r"
      "info_content_l";
    column-gap: 0;
  }

}

@media only screen and (max-width: 600px) {
  .SearchApartmentShow {
    width: 100%;
  }
}