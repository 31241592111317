 .divTable {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 1.5rem;
  }

  table {
    border-collapse: collapse;
  }
  
td {
    border: 2px solid #888888;
    text-align: right;
    padding: 8px;
  }
  th {
    border: 2px solid #888888;
    text-align: right;
    padding: 8px;
    background-color: rgb(78, 78, 78);
    color: white;
    font-weight: 700;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  
  /* @media only screen and (max-width: 600px) {
    .myMessage_largeScreen {
      display: none;
    }
    .myMessage_smallScreen {
      display: block;
      font-weight: 700;
      line-height: 200%;
    }
    .myMessages .divTable {
      width: 90%;
      margin: 20px auto;
      font-size: 1.5rem;
    }
    table {
      border-collapse: collapse;
    }
  
    .myMessages td {
      border: 2px solid #888888;
      text-align: right;
      padding: 8px;
    }
    th {
      border: 2px solid #888888;
      text-align: right;
      padding: 8px;
      background-color: rgb(230, 230, 230);
      color: rgb(0, 0, 0);
      font-weight: 700;
    }
  
    td {
      background-color: #ffffff;
    }
    .message {
      border: 1px solid black;
      padding: 10px;
      border-radius: 5px;
      height: auto;
      position: relative;
    }
    .message span {
      font-weight: 500;
      position: absolute;
      right: 50%;
    }
    .message p {
      font-weight: 500;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    .sort {
      font-weight: 500;
      position: relative;
      width: 100%;
      text-align: left;
      text-decoration: underline;
    }
  } 
   */