div#frame {
    width: calc(100% - 6rem);
    min-height: 40vh;
    padding: 3rem 3rem 8rem 3rem;
    position: relative;
    background-image: url("../../assets/backgroundNoticeBoard.jpg");
    /* background-image: url("https://res.cloudinary.com/dnlwa7r5c/image/upload/v1681978517/imagesWeb/backgroundNoticeBoard_nngssy.jpg"); */
    /* background-image: url("http://res.cloudinary.com/cspaveljb/image/upload/v1499110969/cork_w2tt25.jpg"); */
    background-size: 1300px;
    border-image-source: url("../../assets/borderNoticeBoard.png");
    /* border-image-source: url("https://res.cloudinary.com/dnlwa7r5c/image/upload/v1681978777/imagesWeb/borderNoticeBoard_kfhl4t.png"); */
    /* border-image-source: url("http://res.cloudinary.com/cspaveljb/image/upload/v1499110958/corkborder_f8cm3g.png"); */
    border-image-slice: 31 29;
    border-image-width: 31px 29px;
    margin: 0 auto;
}

.div_all_note {
    -webkit-columns: 3 250px;
    -moz-columns: 3 250px;
    columns: 3 260px;
    margin: 20px auto;
    width: 100%;
    text-align: center;
}

.note {
    min-width: 240px;
    width: 80%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
    border: 1px solid rgba(0, 0, 0, .25);
}

.sticky {
    transform: rotate(-3.5deg);
    -webkit-transform: rotate(-3.5deg);
    -moz-transform: rotate(-3.5deg);
}

.pin {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    background-color: #facbcb;
}

div#frame div:hover.note {
    border: 1px solid rgba(0, 0, 0, .75);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
}