 .boxApartmentHome {
   position: relative;
   margin-top: 2vw;
   width: 100%;
   height: 19vw;
   min-height: 270px;
   border: 0.05vw rgb(211, 211, 211) solid;
   display: grid;
   grid-template-columns: 50% 50%;
   font-weight: 700;
   box-shadow: 0 0 0.5vw 0.1vw rgb(168, 168, 168);
   background-color: white;
 }

 .box_ads_home_page {
   position: relative;
   margin-top: 2vw;
   width: 100%;
   height: 19vw;
   min-height: 270px;
   border: 0.05vw rgb(211, 211, 211) solid;
   font-weight: 700;
   box-shadow: 0 0 0.5vw 0.1vw rgb(168, 168, 168);
   background-color: white;
 }

 .boxApartmentHome .link {
   text-decoration: none;
 }

 .boxApartmentHome .carousel_images_apartment {
   width: 100%;
   height: inherit;
   min-height: inherit;
   display: flex;
   align-items: center;
   justify-content: center;
   border: rgb(211, 211, 211) solid;
   border-width: 0 0 0 0.05vw;
 }

 .boxApartmentHome .divFavoriteIcon {
   position: absolute;
   top: 1vw;
   left: 1vw;
 }

 .boxApartmentHome .nameApartment {
   margin: 1% 10px;
   font-size: 2.5rem;
   color: rgb(187, 0, 0);
   height: 16%;
   display: flex;
   align-items: center;
 }

 .boxApartmentHome .location {
   margin: 1% 10px;
   font-size: 1.7rem;
   color: var(--blue);
   height: 12%;
   display: flex;
   align-items: center;
 }

 .boxApartmentHome .beds {
   margin: 1% 10px;
   font-size: 1.9rem;
   color: var(--blue);
   height: 12%;
   display: flex;
   align-items: center;
 }


 .boxApartmentHome .div_time_rent {
   margin: 1% auto;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 20%;
 }

 .boxApartmentHome .phone {
   width: 50%;
   position: absolute;
   bottom: 16%;
   height: 16%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 2.2rem;
   color: rgb(187, 0, 0);
 }

 .boxApartmentHome .price {
   width: 50%;
   height: 16%;
   background-color: var(--blue);
   color: white;
   position: absolute;
   bottom: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 1.9rem;
 }

 @media only screen and (max-width: 600px) {
   .boxApartmentHome {
     position: relative;
     margin-top: 5vh;
     width: 100%;
     height: auto;
     border: 0.05vw rgb(211, 211, 211) solid;
     display: inline-block;
     font-weight: 700;
     box-shadow: 0 0 2.5vw 0.1vw rgb(168, 168, 168);
   }

   .box_ads_home_page {
     position: relative;
     margin-top: 5vh;
     width: 100%;
     height: auto;
     border: 0.05vw rgb(211, 211, 211) solid;
     display: inline-block;
     font-weight: 700;
     box-shadow: 0 0 2.5vw 0.1vw rgb(168, 168, 168);
   }

   .boxApartmentHome .carousel_images_apartment {
     width: 100%;
     height: 55vw;
     min-height: initial;
     border-width: 0 0 0.05vw 0;
   }

   .boxApartmentHome .divFavoriteIcon {
     position: absolute;
     top: 1vw;
     left: 1vw;
   }

   .boxApartmentHome .nameApartment {
     margin: 1vh 3vw 0;
     font-size: 2.5rem;
     color: rgb(187, 0, 0);
     height: auto;
   }

   .boxApartmentHome .location {
     margin: 0.5vh 3vw 0;
     font-size: 1.7rem;
     color: var(--blue);
     height: auto;
   }

   .boxApartmentHome .beds {
     margin: 0.5vh 3vw 0;
     font-size: 1.6rem;
     color: var(--blue);
     height: auto;
   }

   .boxApartmentHome .div_time_rent {
     margin: 1.5vh auto 0;
     font-size: 1.4rem;
   }

   .boxApartmentHome .phone {
     width: 100%;
     font-size: 2.3rem;
     color: rgb(187, 0, 0);
     position: static;
     margin: 2.5vh auto 0;
   }

   .boxApartmentHome .price {
     width: 100%;
     height: 5vh;
     background-color: var(--blue);
     color: white;
     bottom: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 1.9rem;
     position: static;
     margin: 1vh 0 0 0;
   }
 }