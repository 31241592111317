.div-all-image {
  color: black;
}

.btn_add_img {
  border-radius: 100px;
  height: 50px;
  width: 40px;
  font-weight: 700;
  border: solid 1px var(--blue);
  background-color: var(--blue);
  font-size: 4.5rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

.btn_add_img:hover {
  background: rgb(28, 2, 99, 20%);
  color: var(--blue);
}

.div_btn_add_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div_add_img {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.img_add {
  border: solid 1rem rgb(197, 197, 197);
  width: 25%;
  height: 15vw;
  margin: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: zoom-in;
}

.modalIMG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  /* display: flex;
  justify-content: center;
  align-items: center; */

}

.delete_img {
  position: absolute;
  top: -2rem;
  right: -2rem;
  cursor: pointer;
}

.close_modal {
  position: absolute;
  top: -2rem;
  right: -2rem;
}

.img_modal {
  /* width: 60%; */
  max-height: 80vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
}

.img {
  max-height: 100%;
  max-width: 100%;
}

.div_text_add_img {
  width: 100%;
  font-size: 1.5rem;
  color: var(--blue);
  margin-top: -1.5rem;
}

.div_err_addApartment {
  color: #db0000;
  font-size: 1.5rem;
  font-weight: 400;
  height: 2rem;
}

@media only screen and (max-width: 900px) {
  .img_add {
    width: 40%;
    height: 25vw;
  }
}

@media only screen and (max-width: 650px) {
  .img_add {
    width: 80%;
    height: 50vw;
  }

  .div_text_add_img {
    font-size: 1.2rem;
  }

  .div_add_img {
    justify-content: center
  }
}