.landingPage {
    background: url(../../assets/sky.jpg);
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: fixed;
}

.logo_landingPage {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25rem;
    height: 14rem;
}

.div_carousel {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60%;
    height: 60vh;
}

.div_footer_landingPage {
    position: absolute;
    bottom: 5vh;
    left: 10%;
    width: 80%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.icon_landingPage {
    font-size: 2.2rem !important;
    margin-bottom: -5px;
    margin-inline-start: 20px;
}

.container_arrow_for_login {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.arrow_for_login {
    opacity: 0;
    font-size: 3rem;
    margin: 0 2rem 0 0;
    opacity: 0;
    animation: arrow 2s linear infinite;
}

.arrow_for_login:first-child {
    animation-name: arrow1;
}

.arrow_for_login:nth-child(2) {
    animation-name: arrow2;
}

.arrow_for_login:nth-child(3) {
    animation-name: arrow3;
}

@keyframes arrow1 {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes arrow2 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes arrow3 {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1600px) {
    .div_carousel {
        width: 70%;
    }
}

@media only screen and (max-width: 1250px) {
    .div_carousel {
        width: 80%;
    }
}

@media only screen and (max-width: 1000px) {
    .div_carousel {
        width: 90%;
    }
}

@media only screen and (max-width: 1000px) {
    .div_carousel {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    .logo_landingPage {
        top: 8rem;
        width: 25rem;
        height: 12rem;
    }

    .div_carousel {
        position: absolute;
        top: 18rem;
        height: calc(100vh - 43rem);
    }

    .div_footer_landingPage {
        bottom: 5rem;
        height: 12rem;
    }
}

@media only screen and (max-width: 600px) {
    .logo_landingPage {
        top: 5rem;
        width: 25rem;
        height: 8rem;
    }

    .div_carousel {
        position: absolute;
        top: 10rem;
        height: calc(100vh - 25rem);
    }

    .div_footer_landingPage {
        height: 10rem;
        width: 100%;
        left: 0;

    }

    .icon_landingPage {
        font-size: 1.5rem !important;
        margin-bottom: -3px;
        margin-inline-start: 10px;
    }

    .arrow_for_login {
        font-size: 1.5rem;
        margin: 0.2rem 1rem 0 0;
    }



}