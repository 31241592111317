.privacyPolicy {
    margin: 20px auto;
    width: 80%;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
    font-size: 1.4rem;
    line-height: 1.5;
}

.privacyPolicy_title {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.privacyPolicy_content_title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 5%;
}

.privacyPolicy_content {
    font-size: 1.3rem;
    margin: 10px 8%;
}