.calendar {
  position: relative;
  min-height: 33rem;
}

.react-calendar__tile--now {
  background: var(--purple) !important;
  border-radius: 5px;
}

.react-calendar__tile abbr {
  display: none;
}

.react-calendar__year-view .react-calendar__tile abbr {
  display: block;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  background: none;
  border: none;
}

.react-calendar__navigation {
  width: 60%;
  margin: 0;
  align-items: baseline
}

.react-calendar__navigation button {
  color: var(--blue);
  /* font-size: 1.3rem !important; */
  font-weight: 700;
  min-width: 10px !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 3rem;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 3rem;
}

.react-calendar__navigation__label {
  font-size: 1.4rem !important;
}

.react-calendar__tile--hasActive {
  background: #ffffff !important;
}

.react-calendar__tile {
  /* height: 7rem; */
  padding: 0px !important;
}

.react-calendar__tile--active {
  background-color: var(--white) !important;
  color: var(--white) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--white) !important;
}

.react-calendar {
  height: 100%;
  width: 100% !important;
  max-width: 100%;
  max-height: 100%;
  border: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: 1.2rem;
  background: var(--white);
  color: var(--blue);
}

abbr[title] {
  text-decoration: none !important;
}


.parasha {
  color: var(--red);
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
}

.date_Tile {
  margin: 4px auto;
  width: 80%;
  border: 0.5px solid var(--blue-lite);
  position: relative;
  border-radius: 3px;
  text-align: center;
  color: var(--blue);
  background-color: var(--purple-lite);
}

.react-calendar__tile--now .date_Tile {
  color: var(--white);
  background-color: var(--blue);
}


.date_heb {
  font-size: 1.3rem;
  font-weight: 600;
}

.month_heb {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: -5px;
}

.date_cal {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  background-color: var(--blue);
  width: 100%;
  color: var(--white);
  padding: 3px 0px 1px;
}

.busy {
  opacity: 0.4;
}

#size_div_title {
  width: 35% !important;
  height: 3rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.calendar_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 30%;
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 700;
  position: absolute;
  top: 1rem;
  left: 0rem;
}

.month_heb {
  font-size: 1.1rem;
}


@media only screen and (max-width: 600px) {

  .react-calendar__month-view__weekdays__weekday {
    padding: 0em;
    font-size: 1.1rem !important;
  }

  .date_cal {
    font-size: 1.1rem;
  }

  .date_heb {
    font-size: 1.2rem;
  }

  .month_heb {
    font-size: 1rem;
  }

}