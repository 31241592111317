.div_logo {
  position: relative;
  width: inherit;
  height: inherit;

}

.div_logo .text_logo {
  color: var(--blue);
  font-size: 9rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "GanCLM" !important;
  cursor: pointer;
}

.div_logo .text_logo:nth-child(1) {
  color: var(--blue);
  -webkit-text-fill-color: var(--blue);
  -webkit-text-stroke: 2px var(--blue);
  text-shadow:
    -1.5px -1.5px 0 var(--white),
    1.5px -1.5px 0 var(--white),
    -2px 2px 0 var(--white),
    2px 2px 0 var(--white),
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.div_logo .text_logo:nth-child(2) {
  color: #50c8ffea;
  animation: animate 8s ease-in-out infinite;
  z-index: 1;
}

.div_logo .text_logo:nth-child(3) {
  color: #7bd3fc94;
  animation: animate 6s ease-in-out infinite;
  z-index: 1;
}

.div_logo .text_logo:nth-child(4) {
  color: #a6e3ffae;
  animation: animate 12s ease-in-out infinite;
  z-index: 1;
}

@keyframes animate {

  0%,
  100% {
    clip-path: polygon(0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%);
  }

  50% {
    clip-path: polygon(0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%);
  }
}

.imgLogo {
  height: 8rem;
  width: 8rem;
  position: absolute;
  top: -10%;
  right: 7%;
  z-index: -1;
}

.title_logo {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 2rem;
  font-family: "GanCLM" !important;
  text-align: center;
  -webkit-text-stroke: 0px;
  text-shadow: initial;
  -webkit-text-fill-color: initial;
}

@media only screen and (max-width: 900px) {
  .div_logo .text_logo {
    font-size: 8rem;
  }

  .title_logo {
    font-size: 1.8rem;
  }

  .imgLogo {
    height: 7rem;
    width: 7rem;
  }

}

@media only screen and (max-width: 730px) {
  .div_logo .text_logo {
    font-size: 7rem;
  }

  .title_logo {
    font-size: 1.5rem;
  }

  .imgLogo {
    height: 6rem;
    width: 6rem;
  }
}

@media only screen and (max-width: 600px) {
  .div_logo .text_logo {
    font-size: 5rem;
  }

  .imgLogo {
    height: 4rem;
    width: 4rem;
  }

  .title_logo {
    font-size: 1.1rem;
  }

}