 .icon_time_rent {
   margin: 0 0 -3px 0px;
   width: 2.1rem;
 }

 .RentTime {
   width: 100%;
   font-size: 1.5rem;
   color: var(--blue);
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 2.2rem;
 }

 @media only screen and (max-width: 600px) {
   .icon_time_rent {
     width: 2.1rem;
     color: var(--blue);
   }

   .RentTime {
     width: 100%;
     font-size: 1.5rem;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     row-gap: 10px;
   }
 }