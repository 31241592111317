.details_messages {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--blue);
  height: 100%;
  margin-inline: 10px;
}

.date {
  margin-inline: 10px;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  width: 20%;
}

.div_message {
  width: 100%;
  font-size: 1.2rem;
  margin-inline: 10px;
  color: var(--blue);
}

.back_user {
  font-size: 1.3rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--blue);
  cursor: pointer;
  margin-bottom: 20px;
}

.myMessages {
  width: 96%;
  margin: 10px auto;
  position: relative;
}

.large_screen_mymessage {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  direction: ltr;
  width: 50%;

}

.details_messages_large {
  direction: rtl;

  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--blue);
  /* height: 100%; */
}

.div_content_message {
  width: 30vw;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--blue);
  position: absolute;
  top: 20px;
  right: 55%;

}

.modal_message {
  display: none;
}

@media only screen and (max-width: 600px) {
  .modal_message {
    display: block;
  }

  .message_large_screen {
    display: none;
  }

  .div_content_message {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    border: none;
    border-radius: 0px;
    margin: 20px auto;
    padding: 0px;
    background-color: rgba(255, 255, 255);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--blue);
    text-align: start
  }

  .large_screen_mymessage {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;
  }

  .details_messages_large {
    margin: 20px auto;
  }
}