.div_details {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.div_list_city_item {
  cursor: pointer;
  line-height: 2.3rem;
}

.div_list_city_item:hover {
  background-color: #c9c9c9;
}



.div_details_listCity {
  grid-template-columns: 1fr 1fr 2fr;
}

.br {
  display: none;
}

.div_baby_bed {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.div_button_baby_bed {
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media only screen and (max-width: 900px) {
  .div_details {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .br {
    display: block;
  }

  .div_details_listCity {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 750px) {
  .div_details {
    grid-template-columns: 1fr 1fr;
  }

  .div_details_listCity {
    grid-template-columns: 1fr 1fr;
  }

}

@media only screen and (max-width: 600px) {
  .div_details {
    grid-template-columns: 1fr;
  }

  .div_details_listCity {
    grid-template-columns: 1fr;
  }

}