.App {
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 25rem);
  padding-bottom: 25rem;
}

.top {
  position: fixed;
  bottom: 2vw;
  right: 3vw;
  z-index: 1 !important;
  background-color: white;
  width: 5rem;
  height: 5rem;
  color: rgb(177, 177, 177);
  border: 0.1vw solid rgb(177, 177, 177);
  transform: rotate(-90deg);
  border-radius: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 0 1vw rgb(124, 124, 124);
  box-shadow: 0 0 1vw rgb(124, 124, 124);
  cursor: pointer;
}

.google_ads_bottom {
  display: none;
}

@media screen and (max-width: 600px) {
  .App {
    padding-bottom: 33rem;
  }

  .top {
    bottom: 90px;
    width: 4rem;
    height: 4rem;

  }

  .google_ads_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: white;
    z-index: 100 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}