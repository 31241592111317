.apartmentShow_forHomePage_forHomePage {
  position: relative;
  z-index: 0;
  width: 100%;
}

.apartmentShow_forHomePage .divHeader {
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100%;
  background-color: white;
}

.apartmentShow_forHomePage .div_contact_web {
  position: sticky;
  top: 12rem;
  background-color: rgb(255, 255, 255);
  margin-top: 5rem;
  right: 0;
  z-index: 1000;
  height: fit-content;
}

.apartmentShow_forHomePage .container_apartmentShow {
  display: flex;
  gap: 3rem;
}

.apartmentShow_forHomePage .div_images_slider {
  width: 100%;
  position: relative;
  height: 40vw;
}

.apartmentShow_forHomePage .divCommponent-SendMessage-Calendar {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;
}

.div_map {
  width: 100%;
  height: 50vh;
  margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
  .apartmentShow_forHomePage .div_images_slider {
    height: 50vw;
  }
}

@media only screen and (max-width: 1000px) {
  .apartmentShow_forHomePage .div_images_slider {
    height: 55vw;
  }
}


@media only screen and (max-width: 600px) {
  .apartmentShow_forHomePage .container_apartmentShow {
    width: 95%;
    margin: 0 auto;
    gap: 0;
  }

  .apartmentShow_forHomePage .divCommponent-SendMessage-Calendar {
    width: 100%;
    position: relative;
    display: block;
    margin-top: 50px;
  }

  .div_buttton_isOpenContact {
    position: fixed;
    left: 3rem;
    bottom: 10rem;
    z-index: 100;
    background-color: var(--white);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    padding: 1rem;
  }

  .div_contact_mobile {
    position: fixed;
    bottom: 9rem;
    left: 2.8rem;
    background-color: var(--white);
    z-index: 10000;
    border-radius: 5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: height .5s;
    height: 0px;
    overflow: hidden;
  }

  .div_contact_mobile.visible {
    height: 42rem;
    padding-top: 1rem;
  }

  .buttton_hamburger {
    position: absolute;
    bottom: 1.4rem;
    width: 100%;
    height: 100%;
    z-index: -100;
  }

  .buttton_hamburger.visible {
    z-index: 100;

  }
}