.popup_landingPage {
    width: 40vw;
    max-width: 500px;
    min-width: 300px;
    font-size: 1.6rem;
    padding: 1rem;
    overflow: auto;
    margin: 0 auto;
}

.popup_landingPage a {
    text-decoration: underline;
    color: rgb(0, 0, 255);
}

.open_more {
    text-decoration: underline;
    color: rgb(0, 0, 255);
    margin: 0 auto;
    cursor: pointer;
}

.div_link_popup_landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div_links_popup_landingPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 2rem auto;
}

@media screen and (max-width: 600px) {
    .popup_landingPage {
        width: calc(100% - 40px);
        min-width: 200px;
        font-size: 1.4rem;
    }

}