.div_choose_city_street {
    width: 200%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.div_input_choose {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 750px) {
    .div_choose_city_street {
        grid-row-start: none;
    }
}

@media only screen and (max-width: 600px) {
    .div_choose_city_street {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .div_input_choose {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}