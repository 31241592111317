.screen_personal_page {
    width: calc(100% - 7rem);
    height: calc(100% - 7rem);
    border-radius: 20px;
    background-color: #f8f8f8bf;
    /* animation: animation_background 5s infinite; */
    border: 0.5rem solid var(--blue);
    padding: 3rem;
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--blue);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@keyframes animation_background {
    0% {
        background-color: #f1febda8;
    }

    50% {
        background-color: #febdfda8;
    }

    100% {
        background-color: #f1febda8;
    }
}

.screen_personal_page_p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--blue);
    margin: -0.5rem 2rem;
}

.screen_personal_page_p:nth-child(even) {
    flex-direction: row-reverse;
}


.screen_personal_page_p img {
    width: 10rem;
    margin: 0 3rem;

}

@media only screen and (max-width: 600px) {
    .screen_personal_page {
        width: calc(100% - 4.4rem);
        height: calc(100% - 4.4rem);
        border-radius: 10px;
        border: 0.2rem solid var(--blue);
        padding: 2rem;
        font-size: 1.2rem;
        display: block;
    }

    .screen_personal_page_p {
        font-size: 1.2rem;
        margin: 3rem 0;
    }

    .screen_personal_page_p:nth-child(even) img {
        margin-inline-end: 0;
        margin-inline-start: 1.5rem;
    }

    .screen_personal_page_p img {
        width: 6rem;
        margin: 0;
        margin-inline-end: 1.5rem;
    }

}

@media only screen and (max-width: 450px) {
    .screen_personal_page_p {
        font-size: 1.1rem;
        margin: 2rem 0;
    }

}