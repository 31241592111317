.header_box_users {
    width: 80%;
    display: grid;
    grid-template-columns: 15% 25% 30% 30%;
    font-size: 1.3rem;
}

.box_users {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
    font-size: 1.3rem;
}

@media screen and (max-width: 600px) {
    .header_box_users {
        grid-template-columns: 60% 40%;
    }
}