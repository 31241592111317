body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;

  --blue: rgb(16, 0, 61);
  --blue-lite: rgba(16, 0, 61, .5);
  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);
  --red: #ff0000;
  --orange: rgb(255, 204, 174);
  --purple: rgb(214, 174, 255);
  --purple-lite: rgba(214, 174, 255, .6);

}

html {
  font-size: 62.5%;
}

.scroll_designed::-webkit-scrollbar {
  width: 6px;
}

.scroll_designed::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px 0 0 10px;
}

.scroll_designed::-webkit-scrollbar-thumb {
  background: rgb(2, 0, 129);
  border-radius: 10px 0 0 10px;
}

.container_responsive {
  width: 65%;
  margin: 0 auto;
  position: relative;
}

a {
  text-decoration: none;
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

@font-face {
  font-family: "GanCLM";
  src: local("GanCLM"),
    url("./assets/GanCLM-Bold.ttf") format("truetype");
  font-weight: bold;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

@media only screen and (max-width: 1350px) {
  html {
    font-size: 60%;
  }

  .container_responsive {
    width: 75%;
    margin: 0 auto;
  }

}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 60%;
  }
}

@media only screen and (max-width: 1100px) {
  html {
    font-size: 58%;
  }

  .container_responsive {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 56%;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 52%;
  }

  .container_responsive {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 48%;
  }

  .container_responsive {
    width: 95%;
  }
}

@media only screen and (max-width: 700px) {
  html {
    font-size: 45%;
  }

  /* .container_responsive {
    width: 100%;
  } */

}

@media only screen and (max-width: 600px) {
  html {
    font-size: 62.5%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: 1.01em; */
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0 !important;
  margin-block-end: 1.2rem !important;
}