.login {
  position: relative;
  width: 100%;
}

.login .headerLogin {
  position: relative;
  width: 100%;
  height: 8vh;
  background-color: rgb(6, 0, 61);
  color: white;
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .login-register {
  position: relative;
  width: 100%;
  /* display: flex;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.login .divContainer {
  position: relative;
  width: 70%;
  height: 80%;
  margin: 10%;
  border-radius: 2vw;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.login .divContainer::before {
  content: "";
  position: absolute;
  border-left: 1px solid rgb(180, 180, 180);
  height: 100%;
  top: 0;
  left: -20%;
}

.login .divContainer:last-child:before {
  display: none;
}

.login input {
  border: none;
  width: 100%;
  border-bottom: 1px solid rgb(150, 150, 150);
  background-color: #fff !important;
  height: 20px;
  font-size: 1.6rem;
  margin: 10px auto;
}

.login button {
  width: 70%;
  border: 1px solid rgb(150, 150, 150);
  background-color: white;
  border-radius: 15px;
  font-size: 1.7rem;
  cursor: pointer;
  margin: 10px 15%;
  height: 30px;

  font-weight: 700;
}

.login p {
  font-size: 2.1rem;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.login .divReset {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.5rem;
}

.login .underline {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.small_screen {
  display: none;
}

@media only screen and (max-width: 800px) {
  .login .login-register {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .hr {
    display: none;
  }

  .large_screen {
    display: none;
  }

  .small_screen {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .login .login-register {
    position: relative;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .login .divContainer::before {
    content: none;
  }

  .login .divContainer {
    margin: 20px auto 0;
  }

  .hr {
    display: block;
    width: 80%;
  }
}