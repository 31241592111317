.hamburger_animation {
  position: relative;
  width: 100%;
  height: 100%;
}

.line {
  width: 100%;
  height: 20%;
  background-color: var(--white);
}

.line1 {
  position: absolute;
  top: 0;
  right: 0px;
}


.line2 {
  transition: opacity 1s;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: 0;
}

.line3 {
  position: absolute;
  bottom: 0;
  right: 0px;
}

.line2.close {
  opacity: 0;
}

.line1.hamburger {
  animation: bun-top-hamburger 1s;
  animation-fill-mode: forwards;
}

@keyframes bun-top-hamburger {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }

  20% {
    left: 0px;
    top: -5%;
    transform: rotate(15deg);
  }

  80% {
    left: 0px;
    top: 45%;
    transform: rotate(-65deg);
  }

  100% {
    left: 0px;
    top: 45%;
    transform: rotate(-60deg);
  }
}

.line3.hamburger {
  animation: bun-bottom-hamburger 1s;
  animation-fill-mode: forwards;
}

@keyframes bun-bottom-hamburger {
  0% {
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }

  20% {
    left: -1px;
    bottom: -5%;
    transform: rotate(-15deg);
  }

  80% {
    left: 0px;
    bottom: 35%;
    transform: rotate(65deg);
  }

  100% {
    left: 0px;
    bottom: 35%;
    transform: rotate(60deg);
  }
}

.line1.close {
  animation: bun-top-close 1s;
  animation-fill-mode: forwards;
}

@keyframes bun-top-close {
  100% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }

  80% {
    left: 0px;
    top: -5%;
    transform: rotate(15deg);
  }

  20% {
    left: 0px;
    top: 45%;
    transform: rotate(-65deg);
  }

  0% {
    left: 0px;
    top: 45%;
    transform: rotate(-60deg);
  }
}

.line3.close {
  animation: bun-bottom-close 1s;
  animation-fill-mode: forwards;
}

@keyframes bun-bottom-close {
  100% {
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }

  80% {
    left: -1px;
    bottom: -5%;
    transform: rotate(-15deg);
  }

  20% {
    left: 0px;
    bottom: 35%;
    transform: rotate(65deg);
  }

  0% {
    left: 0px;
    bottom: 35%;
    transform: rotate(60deg);
  }
}