.my_details .div-all-input {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 3rem auto;
}

.link_PrivacyPolicy {
  color: rgb(85, 85, 85);
  font-weight: 700;
  font-size: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 0.8rem;
  cursor: pointer;
  text-decoration: underline !important;
}

.div_err_my_details {
  color: #db0000;
  font-size: 1.5rem;
  font-weight: 400;
  height: 1rem;
}

.my_details .div_all_checkbox {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 3rem auto;
  padding: 0 5%;

}

.my_details .details_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.my_details_header {
  /* text-align: center; */
  font-size: 1.8rem;
  font-weight: 700;
  color: rgb(85, 85, 85);
  margin: 5rem auto 0;
}

@media only screen and (max-width: 600px) {
  .my_details .details_container {
    display: flex;
    flex-direction: column;
  }

  .my_details .div_all_checkbox {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0;

  }

  .my_details .div-all-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
  }


}