.register .divErr {
  height: 2vw;
  color: red;
  line-height: 1vw;
  font-size: 1.5rem;
  font-weight: 400;
}

.register {
  width: 100%;
}

.register .noneDisplay {
  position: absolute;
  top: -10000px;
}

.register .diviconhiden {
  position: relative;
}

.register .iconhiden {
  position: absolute;
  bottom: 15px;
  left: 10px;
  cursor: pointer;
}