.div_bar_page_number {
    width: 80%;
    max-width: 80%;
    height: 5rem;
    /* background-color: #f87171; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    bottom: 35px;
    left: 10%;
    right: 10%;
}


.div_btn_numbers {
    margin: 0 2%;
    overflow: auto;
    padding: 1px;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.332);
    border-radius: 30px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5),
        inset 5px 5px 8px rgba(0, 0, 0, 0.5);
}

.div_btn_arrow {
    margin: 0 2%;
    padding: 1px;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.332);
    border-radius: 30px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5),
        inset 5px 5px 8px rgba(0, 0, 0, 0.5);
}

.div_btn_numbers::-webkit-scrollbar {
    height: 5px;
}

.div_btn_numbers::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
    margin: 0 1rem;
}

.div_btn_numbers::-webkit-scrollbar-thumb {
    background: var(--blue-lite);
    border-radius: 10px;
}

.btn_page_number {
    border-radius: 50%;
    background-color: #ffffff85;
    width: 3rem;
    border: none;
    margin: 0 1rem;
    color: var(--blue);
    font-size: 1.7rem;
    font-weight: 700;
    cursor: pointer;
    transition: all .3s ease;
}

.btn_page_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}