.MaintenanceScreen {
  position: relative;
  width: fit-content;
  padding: 20px 20px;
  margin: 50px auto;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--blue);
  max-width: 70%;
}

.div_logo_maintenance {
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  margin: 0 auto 3rem;
  max-width: 90%;
}