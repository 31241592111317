 .div_input {
   position: relative;
   margin: 2.3rem auto;
 }

 .label_input {
   border: var(--blue-lite) 0.1vw solid;
   border-radius: 10vw;
   background-color: var(--white);
   margin-right: 0.6vw;
   height: 0.5rem;
   top: -1.7rem;
   position: absolute;
   color: var(--blue);
   padding: 1rem 1.5rem;
   line-height: 0.5vw;
   font-weight: 600;
   font-size: 1.2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10;
 }

 #disabledSelect {
   color: var(--blue-lite);
 }

 .input {
   height: 3.8rem;
   border-radius: 4px;
   border: var(--blue-lite) 0.1vw solid;
   width: calc(100% - 2rem);
   font-size: 1.5rem;
   font-weight: 600;
   padding: 0.5rem 1rem;
   background-color: rgb(255, 255, 255);
   color: var(--black);
 }

 #textarea {
   padding: 1.2rem;
   overflow: auto;
   scroll-padding: 50px;
   line-height: 2.5rem;
 }

 .input:hover {
   border: var(--blue) 0.1vw solid;
 }

 .input:focus {
   border: var(--blue) 0.1vw solid;
 }

 #width-select {
   width: calc(100% + 2px);
   height: calc(3.8rem + 1.2rem);
 }

 .div_options {
   position: absolute;
   border-radius: 5px;
   width: 100%;
   max-height: 20rem;
   background-color: #fff;
   z-index: 1;
   overflow: auto;
   text-align: center;
   font-size: 1.4rem;
   color: black;
   box-shadow: 0px 0px 3px #666666;
   z-index: 100;
 }

 .div_options::-webkit-scrollbar {
   width: 10px;
 }

 .div_options::-webkit-scrollbar-track {
   box-shadow: inset 0 0 4px grey;
   border-radius: 10px;
   border: 1px solid rgba(0, 0, 0, 0.36);
 }

 .div_options::-webkit-scrollbar-thumb {
   background: #c2c2c2;
   border-radius: 10px;
 }

 .div_err_input {
   color: #db0000;
   font-size: 1.5rem;
   font-weight: 400;
   height: 1rem;
 }


 [placeholder]:empty:before {
   content: attr(placeholder);
   color: rgb(133, 133, 133);
   background-color: transparent;
 }

 .btn_component {
   font-weight: 700;
   font-size: 2rem;
   color: var(--purple);
   background-color: var(--blue);
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 2rem auto;
   border: var(--purple) 2px solid;
   position: relative;
 }

 .div_open_hover {
   position: relative;
   display: flex;
   height: 100%;
   background-color: transparent;
 }

 .div_open_hover:hover .content_div_open_hover {
   width: 100%;
   position: absolute;
   top: 100%;
   left: 0;
   display: block;
   z-index: 10000;
 }


 .content_div_open_hover {
   display: none;
 }

 .btn_component:after {
   border-radius: inherit;
   content: "";
   position: absolute;
   top: -10px;
   left: -10px;
   right: -10px;
   bottom: -10px;
   z-index: -1;
   background-color: var(--blue);
 }

 .disabled:hover {
   opacity: 0.8;
 }


 .btn_component:hover {
   background: var(--purple);
   color: var(--blue);
 }

 .checkbox {
   position: relative;
   width: 100%;
   margin: 0 0 1.2rem 0;
   display: flex;
   flex-direction: row;
   align-items: center
 }

 .checkbox label {
   color: rgb(41, 41, 41);
   font-weight: 700;
   font-size: 1.5rem;
   margin-right: 1rem;
   display: flex;
   flex-direction: row;
   align-items: flex-end
 }

 .icon_arrow_down {
   position: absolute;
   height: 5rem;
   top: 0;
   left: 0;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   pointer-events: none;
 }

 .div_show_checked {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
   height: 100%;
   overflow: hidden;
   padding: 0.4rem 0 0 0;
 }

 .div_checked_title {
   width: fit-content;
   border: 0.5px solid var(--blue-lite);
   border-radius: 5px;
   padding: 0 5px;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.2rem;
   margin: 0.2rem 0.2rem;
 }




 @media only screen and (max-width: 600px) {
   .btn_component {
     font-size: 1.3rem;
   }

   .div_open_hover:hover .content_div_open_hover {
     width: 300%;
     right: 0vh;
   }

   .btn_component:after {
     top: -8px;
     left: -8px;
     right: -8px;
     bottom: -8px;
   }
 }