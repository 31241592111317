.select_Navigate {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_Navigate .select_navigate_label_active {
  color: var(--white);
  background-color: var(--orange);
  font-size: 1.8rem;
  font-weight: 700;
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  margin: -12px 0 0 0;
  background: linear-gradient(var(--purple), var(--blue) 25%, var(--blue) 50%, var(--blue) 75%, var(--purple) 100%);
}

.select_Navigate .select_navigate_label {
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  position: relative;
}

.select_Navigate .view_navigate {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select_Navigate .icon_arrow_down_select_navigate {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select_Navigate .div_select_navigate {
  margin-top: 15px;
}

.select_Navigate .div_btn_navigate {
  width: calc (100% - 20px);
  background-color: var(--blue);
  border-bottom: rgb(222, 241, 255) 1px solid;
  padding: 10px;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
}

.select_Navigate .div_btn_navigate:hover {
  background-color: rgb(222, 241, 255);
  color: var(--blue);
}


.select_Navigate .divShowFavorite {
  width: 15%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .select_Navigate .select_navigate_label {
    font-size: 1.2rem;
  }

  .select_Navigate .select_navigate_label_active {
    font-size: 1.2rem;
  }


  .select_Navigate .view_area {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .select_Navigate .div_btn_filterArea {
    font-size: 1.1rem;
    font-weight: 500;
  }
}