.bar_navigation {
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--blue);
  border-bottom: rgb(222, 241, 255) 3px solid;
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.div_navigate {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}


.div_navigate+.div_navigate {
  content: "  ";
  border-right: 1px solid white;
}

.bar_navigation .label_small {
  font-size: 1.3rem;
  line-height: 100%;
}

.google_ads_r {
  position: absolute;
  top: 120px;
  right: 0;
  width: 200px;
  height: 600px;
  /* background-color: rgb(255, 255, 255); */
}

.google_ads_l {
  position: absolute;
  top: 120px;
  left: 0;
  width: 200px;
  height: 600px;
  /* background-color: rgb(255, 255, 255); */
}

@media screen and (max-width: 1100px) {
  .google_ads_r {
    display: none;
  }

  .google_ads_l {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .bar_navigation .label_small {
    display: none;
  }

  .div_navigate:nth-child(1) {
    width: 20%;
  }

  .div_navigate:nth-child(2) {
    width: 30%;
  }

  .div_navigate:nth-child(3) {
    width: 30%;
  }

  .div_navigate:nth-child(4) {
    width: 20%;
  }

}