.apartmentShow_forHomePage .divMore {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 7.2%;
}

.apartmentShow_forHomePage .divMore::after {
  content: "";
  flex: auto;
}

.divMore .boxApartment {
  position: relative;
  margin-top: 30px;
  width: 28%;
  height: 27vw;
  border: 0.1px rgb(16, 0, 61) solid;
  box-shadow: 0 0 2px rgb(16, 0, 61);
}

.divMore .imgApartment {
  position: absolute;
  top: 0vw;
  width: 100%;
  height: 11vw;
  z-index: 0;
  /* object-fit: contain; */
  border-bottom: 1px var(--blue) solid;
}

.divMore .addToFavorite {
  position: absolute;
  top: 1vw;
  right: 1vw;
  z-index: 1;
}

.divMore .infoApartment {
  position: absolute;
  top: 11vw;
  height: 16vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.divMore .nameApartment {
  font-size: 2rem;
  color: rgb(187, 0, 0);
  height: auto;
}

.divMore .location {
  font-size: 1.5rem;
  color: var(--blue);
}

.divMore .beds {
  font-size: 1.8rem;
  color: var(--blue);
}

.divMore .phone {
  font-size: 2rem;
  color: rgb(187, 0, 0);
}

.divMore .price {
  width: 100%;
  height: 3vw;
  background-color: var(--blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
}

@media only screen and (max-width: 600px) {
  .divMore .boxApartment {
    width: 44%;
    height: 210px;
    gap: 5%
  }

  .divMore .imgApartment {
    height: 90px;
  }

  .divMore .infoApartment {
    top: 90px;
    height: 120px;
  }

  .divMore .nameApartment {
    font-size: 1.5rem;
    color: rgb(187, 0, 0);
  }

  .divMore .location {
    font-size: 1.2rem;
    color: var(--blue);
  }

  .divMore .beds {
    font-size: 1.4rem;
    color: var(--blue);
  }

  .divMore .phone {
    font-size: 1.5rem;
    color: rgb(187, 0, 0);
  }

  .divMore .price {
    height: 20px;
    font-size: 1.3rem;
  }
}