 .title_my_favorite {
   font-size: 2rem;
   font-weight: 700;
   color: var(--blue);
   margin: 3rem 0px;
 }

 .iconError {
   color: var(--red);
   margin: 0 0.5rem;
 }

 .div_list_page_favorite {
   width: 90%;
   margin: 0 auto;
 }

 .div_list_page_favorite .box_responsive_page_favorite {
   width: calc(80% - 4rem);
   position: relative;
   margin: 3rem auto;
 }

 .div_list_page_favorite .box_apartment_page_favorite {
   height: 10rem;
   display: flex;
   align-items: center;
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   border: 1px solid rgb(207, 207, 207);
   position: relative;
 }

 .div_list_page_favorite .div_add_favorite {
   position: absolute;
   top: 0.6rem;
   right: 0.6rem;
   width: 2rem;
   height: 2rem;
   padding: 0.3rem;
   border-radius: 50%;
   background-color: white;
   box-shadow: 0 0 5px rgb(207, 207, 207);
   cursor: pointer;
   transition: transform 0.3s ease;
   z-index: 1;
 }

 .div_list_page_favorite .div_add_favorite:hover {
   transform: scale(1.3);
 }

 .div_list_page_favorite .img_apartment_page_favorite {
   width: 30%;
   height: 100%;
   border-radius: 5px;
   border-left: 1px solid rgb(207, 207, 207);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
 }

 .box_apartment_page_favorite .info_apartment_page_favorite {
   width: calc(45% - 4rem) !important;

 }

 .div_list_page_favorite .info_apartment_page_favorite {
   width: calc(35% - 4rem);
   height: calc(100% - 2rem);
   padding: 1rem 2rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

 }


 .div_list_page_favorite .name_apartment_page_favorite {
   font-size: 1.6rem;
   font-weight: 700;
   color: var(--blue);
 }

 .div_list_page_favorite .city_apartment_page_favorite {
   font-size: 1.2rem;
   font-weight: 400;
   color: var(--blue);
 }

 .div_list_page_favorite .phone_apartment_page_favorite {
   font-size: 1.4rem;
   font-weight: 700;
   color: var(--blue);
 }

 .box_replacementSearch_page_favorite {
   display: flex;
   flex-direction: column;
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   border: 1px solid rgb(207, 207, 207);
   margin: 1rem auto;
   position: relative;
 }

 .title_replacementSearch_page_favorite {
   font-size: 1.6rem;
   font-weight: 700;
   color: var(--blue);
   /* background-color: var(--purple); */
   margin: 0.5rem 0;
   text-align: center;
   border-bottom: 1px solid rgb(207, 207, 207);
 }

 .info_replacementSearch_page_favorite {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
 }

 .div_icon_replacementSearch {
   width: 10%;
   height: auto;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .info_contact_page_favorite {
   width: 20%;
 }

 .message_no_apartments {
   width: fit-content;
   margin: 3rem auto;
   font-size: 1.5rem;
   text-align: center;
 }



 @media screen and (max-width: 800px) {
   .div_list_page_favorite .box_responsive_page_favorite {
     width: calc(90% - 4rem);
   }


 }

 @media screen and (max-width: 600px) {
   .title_my_favorite {
     margin: 2rem 0 0;
   }

   .div_list_page_favorite .box_responsive_page_favorite {
     width: calc(100% - 0.6rem);
   }

   .div_list_page_favorite .box_apartment_page_favorite {
     display: grid;
     grid-template-columns: 40% 60%;
     grid-template-rows: 9rem 4rem;
     height: 13rem;
   }

   .box_replacementSearch_page_favorite {
     width: calc(100% - 0.6rem);
   }

   .div_list_page_favorite .img_apartment_page_favorite {
     width: 100%;
     height: 9rem;
   }

   .box_apartment_page_favorite .info_apartment_page_favorite {
     width: calc(100% - 2rem) !important;
     height: calc(9rem - 2rem);
     padding: 1rem 2rem 1rem 0;
   }

   .div_list_page_favorite .info_contact_page_favorite {
     height: 100%;
     width: 100%;
     grid-row: 2 / 3;
     grid-column: 1 / 3;
     display: flex;
     flex-direction: row;
     align-content: center;
     justify-content: center;
     align-items: center;
     column-gap: 2rem;
     border-top: 1px solid rgb(207, 207, 207);
   }


   .div_list_page_favorite .title_replacementSearch_page_favorite {
     width: 100%;
     height: 3rem;
   }

   .div_list_page_favorite .info_replacementSearch_page_favorite {
     width: calc(100% - 0.6rem);
     display: grid;
     grid-template-columns: 45% 10% 45%;
     grid-template-rows: auto auto;
   }

   .info_replacementSearch_page_favorite .info_apartment_page_favorite {
     width: calc(100% - 2rem);
     height: auto;
     padding: 1rem;
     text-align: center;
   }

   .info_replacementSearch_page_favorite .div_icon_replacementSearch {
     width: 100%;
   }

   .div_list_page_favorite .name_apartment_page_favorite {
     font-size: 1.4rem;
   }

   .div_list_page_favorite .phone_apartment_page_favorite {
     font-size: 1.2rem;
   }

   .info_replacementSearch_page_favorite .info_contact_page_favorite {
     height: 3rem;
     width: 100%;
     grid-row: 2 / 3;
     grid-column: 1 / 4;
     display: flex;
     flex-direction: row;
     align-content: center;
     justify-content: center;
     align-items: center;
     column-gap: 2rem;
     border-top: 1px solid rgb(207, 207, 207);
   }

 }