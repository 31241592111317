 .send_message {
   position: relative;
   width: 100%;
   margin: 1rem auto 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 38rem;
 }

 .input_send_message {
   width: 80%;
   height: 2rem;
   border: var(--blue-lite) 1px solid;
   border-radius: 12px;
   text-align: center;
   padding: 0.5rem 2.5%;
   background-color: rgb(255, 255, 255);
   margin: 0 auto;
   font-size: 1.5rem;
 }

 #message {
   height: 16rem;
   overflow: auto;
   color: var(--blue);
 }

 .error_formik {
   height: 2.5rem;
   color: red;
   font-size: 1.3rem;
   font-weight: 200;
   text-align: start;
   margin: 0 auto;
   width: 80%;

 }

 [placeholder]:empty:before {
   content: attr(placeholder);
   color: gray;
   background-color: transparent;
   line-height: 150%;
 }

 @media only screen and (max-width: 600px) {
   .apartmentShowComponent .sendMessage {
     position: relative;
     width: 100%;
     height: auto;
   }

   .apartmentShowComponent .allSendMessage {
     width: calc(100% - 4px);
     position: relative;
     border: rgb(16, 0, 61) 2px solid;
     border-top: rgb(16, 0, 61) 5px solid;
     color: rgb(24, 0, 90);
     font-weight: 700;
     padding: 0;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-around;
     height: auto;
   }

   .apartmentShowComponent input {
     width: 100%;
     padding: 0;
     margin: 15px auto;
   }

   .apartmentShowComponent #message {
     width: 90%;
     padding: 5%;
     margin: 15px auto 40px;
   }

   /* .sendMessage input {
    width: 80%;
    height: 30px;
    border: rgb(16, 0, 100) 1px solid;
    border-radius: 12px;
    text-align: center;
    padding: 5px 15px;
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    font-size: 1.5rem;
  }
  .sendMessage #message {
    width: 80%;
    height: 90px;
    border: rgb(16, 0, 100) 0.2vw solid;
    border-radius: 12px;
    text-align: center;
    overflow: auto;
    padding: 15px 15px;
    background-color: rgb(255, 255, 255);
    font-size: 1.5rem;
    margin-top: 20px;
  } */
 }