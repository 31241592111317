.div_list_city_item {
  cursor: pointer;
  line-height: 2.3rem;
}

.div_list_city_item:hover {
  background-color: #c9c9c9;
}


.div_choose_city {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  height: 10rem;
  overflow-y: auto;
  border: 1px solid var(--blue-lite);
  border-radius: 5px;
  margin: -1rem auto 2.3rem auto;
  padding: 0.5rem;
}

.choose_city {
  margin: 4px 7px;
  padding: 0 5px 0 0;
  border: var(--blue-lite) 0.1vw solid;
  height: 2rem;
  font-size: 1.4rem;
  color: var(--blue);
  background-color: var(--white);
  font-weight: 400;
  position: relative;
  display: flex;
  gap: 0.6rem;
  border-radius: 5px;
  flex-direction: row;
}

.span_delete_city {
  width: 1.5rem;
  font-size: 1.2rem;
  color: var(--white);
  background-color: var(--blue-lite);
  cursor: pointer;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}