.bar {
  height: 14rem;
  width: 75%;
  position: relative;
  top: 0;
  padding: 0 12.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1vw solid rgb(207, 207, 207);
}

.bar .div_logo_barHeader {
  width: 25rem;
  height: 100%;
}

.bar .divBar {
  height: 120px;
  /* width: 14; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "GanCLM" !important;
  font-weight: 700;
}

.bar .divConnect {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
  flex-direction: column;
  font-family: "GanCLM" !important;
  color: var(--blue);
}

.bar .divConnect_modal {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 10px;
  /* flex-direction: column; */
}

.bar .div_modal {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
}

.bar .div_link_exit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

@media only screen and (max-width: 1200px) {
  .bar {
    width: 80%;
    padding: 0 10%;

  }
}

@media only screen and (max-width: 1000px) {
  .bar {
    width: 90%;
    padding: 0 5%;
  }
}

@media only screen and (max-width: 700px) {
  .bar {
    width: 95%;
    padding: 0 2.5%;
  }
}


@media only screen and (max-width: 600px) {
  .bar {
    height: 90px;
    width: 92%;
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1vw solid rgb(207, 207, 207);
    padding: 0 4%;
  }

  .bar .divBar {
    height: 90px;
    /* width: 14; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bar .div_logo_barHeader {
    width: 14rem;
  }

  .bar .allConnect {
    position: absolute;
    top: 70px;
    left: 5%;
  }

  .bar .divConnect {
    font-size: 1.1rem;
  }

  .bar .divConnect_modal {
    font-size: 1.1rem;
    line-height: 100%;
  }
}