.addApartment {
  width: 100%;
  min-width: 55vw;
}

.addApartment .div-all-input {
  width: calc(100% - 2rem);
  display: grid;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.addApartment .content {
  width: calc(100% - 2px);
  margin: 1px auto;
  overflow: hidden;

  padding: 30px 0 0 0;
  color: var(--blue);
}




.div_btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.msgErr {
  color: #db0000;
  font-size: 1.5rem;
  font-weight: 400;
  height: 1rem;
}

@media screen and (max-width: 1350px) {
  .addApartment {
    min-width: 65vw;
  }

}

@media screen and (max-width: 1100px) {
  .addApartment {
    min-width: 75vw;
  }

}