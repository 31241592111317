.footer {
  background-color: var(--blue);
  height: 20rem;
  width: 100%;
  margin: 5rem auto 0;
  position: absolute;
  bottom: 0;
  color: var(--white);
  display: flex;
  align-items: center;
}

.footer_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80%;
  /* border: 1px solid white; */
}

.footer_div_content {
  width: 25%;
  height: 100%;
}

.header_footer_div_content {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 1rem;
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
  width: 100%;
}

.footer_div_links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1.4rem;
}

.icon_footer {
  font-size: 1.8rem !important;
  color: red;
  margin-inline-end: 1.2rem;
  margin-bottom: -5px;
}

.footer_div_logo {
  width: 25rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .footer {
    height: 28rem;
  }

  .footer_div_logo {
    display: none;
  }

  .footer_content {
    justify-content: space-evenly;
  }

  .footer_div_content {
    width: 40%;
  }

  .footer_div_links {
    font-size: 1.3rem;
  }
}