.Search_Component {
  width: calc(100% - 40px);
  position: relative;
  margin: 20px auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 10px 20px 0 20px;
  background-color: white;
}

.Header_Search_Component {
  font-size: 2rem;
  font-weight: 600;
  color: var(--blue);
}

.Inputs_Search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
}

.div_input_beds {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
}

.Search_Component .input_beds {
  border: none;
  border-bottom: 1px solid var(--blue);
  width: 20%;
}

.Search_Component .Div_Selected_City {
  border: 1px solid var(--blue);
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 400;
  padding: 0px 3px;
  width: fit-content;
  margin: 2px;
}

.Search_Component .Div_All_Selected_City {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
}

.Search_Component .Div_All_Selected_City:hover {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: inherit;
  background-color: white;
  /* height: 1000px; */
}

.div_icon_search_mobile {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .Search_Component {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }

  .Inputs_Search {
    flex-direction: column;
    align-items: center;
  }
}