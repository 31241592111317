.screen_contactUs {
    width: calc(100% - 7rem);
    height: calc(100% - 7rem);
    border-radius: 20px;
    background-color: #f8f8f8bf;
    /* animation: animation_background 5s infinite; */
    border: 0.5rem solid var(--blue);
    padding: 3rem;
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--blue);
    overflow: auto;

}

.div_contactUs {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--blue);
}

.logo_contactUs {
    width: 100%;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.screen_contactUs_l {
    width: 50%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 5% 0;
    z-index: 1;
    position: relative;
}

.screen_contactUs_r {
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% 5%;

}

.screen_contactUs_footer {
    width: 80%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--blue);
    position: absolute;
    bottom: 0;
    left: 10%;

}

@media only screen and (max-width: 700px) {
    .div_contactUs {
        display: block;
    }

    .logo_contactUs {
        height: 12rem;
    }

    .screen_contactUs_r {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }

    .screen_contactUs_l {
        width: 80%;
        margin: 5% auto;
        height: auto;
    }

    .screen_contactUs_footer {
        height: auto;
        font-size: 1.6rem;
        margin: 5% auto;
        position: relative;
        bottom: auto;
        left: auto;
    }
}

@media only screen and (max-width: 600px) {
    .div_contactUs {
        font-size: 1.7rem;
    }

    .logo_contactUs {
        height: 11rem;
    }

    .screen_contactUs_l {
        width: 90%;
        margin: 5% auto;
        height: auto;
    }

    .screen_contactUs_footer {
        height: auto;
        font-size: 1.2rem;
        margin: 5% auto;
        position: relative;
        bottom: auto;
        left: auto;
    }
}