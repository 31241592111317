 .title_contact_us {
   font-size: 2rem;
   font-weight: 700;
   color: var(--blue);
   margin: 3rem auto;
   width: 90%;
   max-width: 500px;
 }

 .content_contact_us {
   width: 90%;
   margin: 0 auto;
   max-width: 400px;
 }

 @media screen and (max-width: 600px) {
   .title_contact_us {
     margin: 2rem 0 0;
   }
 }